import logo from "./logo.svg";
import "./App.scss";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { HomePage } from "./pages/HomePage";
import { AboutUsPage } from "./pages/AboutUsPage";
import { ContactPage } from "./pages/ContactPage";
import { ProductsPage } from "./pages/ProductsPage";
import { MultiStageFrac } from "./pages/MultiStageFrac";
import { ConventionalTools } from "./pages/ConventionalTools";
import { PageNotFound } from "./pages/PageNotFound";
import { TermsConditions } from "./pages/TermsConditions";

function App() {
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/contact-us" element={<ContactPage />} />
      {/* <Route path="/products" element={<ProductsPage />} />
      <Route
        path="/products/multi-stage-fracturing-systems"
        element={<MultiStageFrac />}
      />
      <Route
        path="/products/conventional-tools"
        element={<ConventionalTools />}
      /> */}
      <Route path="/terms-and-conditions/" element={<TermsConditions />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
