import React, { useEffect } from "react";
import { ContactHero } from "../components/ContactHero/ContactHero";
import { ContactAccordion } from "../components/ContactAccordions/ContactAccordion";
import { Helmet } from "react-helmet";

export function ContactPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us | Tryton Toll Services</title>
      </Helmet>
      <ContactHero />
      <ContactAccordion />
    </main>
  );
}
