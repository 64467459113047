import React from "react";
import { MdPhone } from "react-icons/md";
import { IoIosPhonePortrait } from "react-icons/io";

export function FieldOffices(props) {
  const contacts = [
    {
      name: "Lloydminster",
      title: "Corporate and HSE",
      officeNum: "780.875.0800 (24 hr.)",
      cellNum: "",
      email: "",
      address: "6702-56 St. Lloydminster, AB T9V 3A7",
    },
    {
      name: "Calgary",
      title: "Corporate Sales and Marketing Office",
      cellNum: "",
      email: "",
      address: "205 5th Ave SW, Suite 1410, Calgary, AB, T2P 2V7",
    },
    {
      name: "Edmonton",
      title: "Primary Western Canadian Distribution Centre",
      officeNum: "780.468.0909 (24 hr.)",
      cellNum: "",
      email: "",
      address: "1, 6404 Roper Rd. Edmonton, AB T6B 3P9",
    },
    // {
    //   name: "Nisku",
    //   title: "",
    //   officeNum: "",
    //   cellNum: "",
    //   email: "",
    //   address: "",
    // },
    {
      name: "Brooks",
      title: "",
      officeNum: "403.362.8600 (24 hr.)",
      cellNum: "",
      email: "",
      address: "#1-2, 515-1st Ave. E Brooks, AB T1R 1B8",
    },
    {
      name: "Drayton Valley",
      title: "",
      officeNum: "780.542.7070",
      cellNum: "",
      email: "",
      address: "5622 - 56 St. Drayton Valley, AB T7A 0B2",
    },
    {
      name: "Grande Prairie",
      title: "",
      officeNum: "780.832.0011 (24 hr.)",
      cellNum: "",
      email: "",
      address: "8801 - 150th Avenue Grande Prairie, AB T8X 0V6",
    },
    {
      name: "High Level",
      title: "",
      officeNum: "780.926.2624 (24 hr.)",
      cellNum: "",
      email: "",
      address: "10497 92nd St. High Level, AB T0H 1Z0",
    },
    {
      name: "Red Deer",
      title: "",
      officeNum: "403.348.0062 (24 hr.)",
      cellNum: "",
      email: "",
      address: "1, 7836 - 49th Ave. Red Deer, AB T4P 2B5",
    },
    {
      name: "Slave Lake",
      title: "",
      officeNum: "780.849.2342 (24 hr.)",
      cellNum: "",
      email: "",
      address: "900 8th St. Slave Lake, AB T0G 2A1",
    },
    {
      name: "Whitecourt",
      title: "",
      officeNum: "780.706.2555 (24 hr.)",
      cellNum: "",
      email: "",
      address: "3421-41 Ave. Whitecourt, AB T7S 1N8",
    },
    {
      name: "Fort St. John",
      title: "",
      officeNum: "250.261.7922",
      cellNum: "",
      email: "",
      address: "10219-95 Ave Fort St. John, BC V1J 5J5",
    },
    {
      name: "Estevan",
      title: "",
      officeNum: "306.634.4503",
      cellNum: "",
      email: "",
      address: "115 Lamoro St. Estevan, SK S4A 1C8",
    },
  ];

  return (
    <div className="contactBlock">
      {contacts.map((contact, index) => (
        <div className="contactItem" key={index}>
          <h5 className="contactName bodyText">{contact.name}</h5>
          <p className="contactTitle bodyText">{contact.title}</p>
          <address className="contactTitle bodyText">{contact.address}</address>

          {contact.officeNum && (
            <div className="iconInfoContainer">
              <MdPhone style={{ color: "#008061" }} />
              <a
                href={`tel:${contact.officeNum.replace(/\s*\(24 hr\.\)/, "")}`}
              >
                <p className="contactNumber bodyText clickableLink">
                  {contact.officeNum}
                </p>
              </a>
            </div>
          )}
          {contact.cellNum && (
            <div className="iconInfoContainer">
              <IoIosPhonePortrait style={{ color: "#008061" }} />
              <a href={`tel:${contact.cellNum}`}>
                <p className="contactNumber bodyText clickableLink">
                  {contact.cellNum}
                </p>
              </a>
            </div>
          )}
          <a href={`mailto:${contact.email}`}>
            <p className="contactTitle bodyText clickableLink">
              {contact.email}
            </p>
          </a>
        </div>
      ))}
    </div>
  );
}
