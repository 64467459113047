import React, { useState, useEffect } from "react";
import Logo from "../../assets/tt-logo-white.svg";
import { Link, useLocation } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { IoIosClose } from "react-icons/io";
import { Input } from "antd";
import { RxCaretDown } from "react-icons/rx";
import { IoMenuSharp } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { IoIosConstruct } from "react-icons/io";
import { Collapse } from "antd";

import OurProductLine from "../../assets/Tryton-OilfieldRentals.png";
import OperationsArea from "../../assets/map.jpg";

export function Nav(props) {
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showConstructionInfo, setShowConstructionInfo] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    const closeMobileMenu = () => {
      if (window.innerWidth > 950) {
        setShowMobileMenu(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", closeMobileMenu);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", closeMobileMenu);
    };
  }, []);

  return (
    <div className={`navContainer ${scrolled ? "navScrolled" : ""}`}>
      <header>
        <div>
          <Link to="/">
            <img
              src={Logo}
              alt="Logo"
              className={`navLogo  ${scrolled ? "navLogoScrolled" : ""}`}
            />
          </Link>
        </div>
        <div>
          <nav className="mainNav">
            <Input
              type="text"
              className={`searchBox  ${
                searchMode === true ? "searchBoxShow" : ""
              }`}
            />
            <Link to="/" style={{ textDecoration: "none" }}>
              <p
                className={`navLink ${
                  location.pathname === "/" ? "navLinkActive" : ""
                }`}
              >
                Home
              </p>
            </Link>
            <Link to="/about-us" style={{ textDecoration: "none" }}>
              <p
                className={`navLink ${
                  location.pathname === "/about-us" ? "navLinkActive" : ""
                }`}
              >
                About Us
              </p>
            </Link>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                className={`navLink ${
                  location.pathname === "/products" ? "navLinkActive" : ""
                }`}
                onClick={() => setShowProducts(!showProducts)}
              >
                Products
              </p>
              &nbsp;
              <RxCaretDown
                style={{
                  fontSize: 22,
                  color: "white",
                  transition: "ease 0.4s all",
                  transform: showProducts === true ? "rotate(180deg)" : "",
                }}
              />
            </div>

            {showProducts && (
              <div className="productsDropdown">
                <IoIosConstruct size={50} color="#008061" />
                <h4 className="smallSubhead">
                  This section is under construction
                </h4>

                {/* <div className="leftLinks">
                  <ul>
                    <Link
                      to="/products/conventional-tools"
                      onClick={() => setShowProducts(false)}
                    >
                      <li className="productLink">Conventional Tools</li>
                    </Link>
                    <Link
                      to="/products/multi-stage-fracturing-systems"
                      style={{ textDecoration: "none" }}
                      onClick={() => setShowProducts(false)}
                    >
                      <li className="productLink">Multi-Stage Frac. Systems</li>
                    </Link>
                  </ul>
                </div>
                <div className="productDetailsRight">
                  <Link
                    to="/products/multi-stage-fracturing-systems"
                    style={{ textDecoration: "none" }}
                    onClick={() => setShowProducts(false)}
                  >
                    <div>
                      <img
                        src={OurProductLine}
                        alt=""
                        className="productDetailsImg"
                      />
                      <h5>Our Product Line</h5>
                      <p className="bodyText textCentre">
                        MSFS®, packers, bridge plugs, and more.
                      </p>
                    </div>
                  </Link>
                  <Link
                    to="/products"
                    style={{ textDecoration: "none" }}
                    onClick={() => setShowProducts(false)}
                  >
                    <div>
                      <img
                        src={OperationsArea}
                        alt=""
                        className="productDetailsImg"
                      />
                      <h5>Operations Area</h5>
                      <p className="bodyText textCentre">
                        Strategically located across the WCSB and the U.S.
                      </p>
                    </div>
                  </Link>
                </div> */}
              </div>
            )}

            <Link to="/contact-us" style={{ textDecoration: "none" }}>
              <p
                className={`navLink ${
                  location.pathname === "/contact-us" ? "navLinkActive" : ""
                }`}
              >
                Contact
              </p>
            </Link>
            <span style={{ color: "white" }}>|</span>

            {searchMode || (
              <div className="navSearchBox">
                <CiSearch
                  style={{ fontSize: 32, color: "white", cursor: "pointer" }}
                  onClick={() => setSearchMode(true)}
                />
              </div>
            )}
            {searchMode && (
              <div className="navSearchBox">
                <IoIosClose
                  style={{ fontSize: 42, color: "white", cursor: "pointer" }}
                  onClick={() => setSearchMode(false)}
                />
              </div>
            )}
          </nav>

          <div
            className="mobileMenu"
            onClick={() => {
              setShowMobileMenu(true);
              console.log("000");
            }}
          >
            <IoMenuSharp style={{ fontSize: 34, color: "white" }} />
          </div>
        </div>
      </header>
      {showMobileMenu && (
        <div className="mobileMenuContainer">
          <div className="mobileMenuHeader">
            <Link to="/">
              <img src={Logo} alt="Logo" className={`navLogo  `} />
            </Link>
            <div>
              <IoCloseOutline
                style={{ fontSize: 42, color: "white", cursor: "pointer" }}
                onClick={() => setShowMobileMenu(false)}
              />
            </div>
          </div>
          <div>
            <ul>
              <Link to="/" onClick={() => setShowMobileMenu(false)}>
                <li>Home</li>
              </Link>
              <Link to="/about-us" onClick={() => setShowMobileMenu(false)}>
                <li>About Us</li>
              </Link>
              {/* <Link to="/products" onClick={() => setShowMobileMenu(false)}> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  cursor: "pointer",
                }}
                onClick={() => setShowConstructionInfo(!showConstructionInfo)}
              >
                <li style={{ userSelect: "none" }}>Products</li>{" "}
                <RxCaretDown
                  style={{
                    fontSize: 22,
                    color: "white",
                    transition: "ease 0.4s all",
                    transform:
                      showConstructionInfo === true ? "rotate(180deg)" : "",
                  }}
                />
              </div>

              {showConstructionInfo && (
                <div className="underConstructionMobileInfo">
                  <IoIosConstruct size={50} color="#008061" />
                  <h4 className="smallSubhead">
                    This section is under construction
                  </h4>
                </div>
              )}

              {/* </Link> */}
              <Link to="/contact-us" onClick={() => setShowMobileMenu(false)}>
                <li>Contact</li>
              </Link>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
