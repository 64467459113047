import React from "react";
import { MdPhone } from "react-icons/md";
import { IoIosPhonePortrait } from "react-icons/io";

export function SalesUS(props) {
  const contacts = [
    {
      name: "Paul Woodall",
      title: "Tryton USA Technical Sales — \nSeguin, TX",
      officeNum: "",
      cellNum: "832.517.1935",
      email: "pwoodall@trytontoolsusa.com",
    },
    {
      name: "Ron Shifflett",
      title: "Tryton USA Technical Sales — \nSeguin, TX",
      officeNum: "",
      cellNum: "281.798.1091",
      email: "rshifflett@trytontoolsusa.com",
    },
    {
      name: "Brandon Darnold",
      title: "Tryton USA Technical Sales – \nMidland, TX",
      officeNum: "432.561.8823",
      cellNum: "432.272.9249",
      email: "bdarnold@trytontoolsusa.com",
    },
    {
      name: "David Zimmerhanzel",
      title: "Tryton USA Technical Sales – \nSeguin, TX",
      officeNum: "",
      cellNum: "361.217.0464",
      email: "dzimmerhanzel@trytontoolsusa.com",
    },
    {
      name: "Domestic Sales",
      title: "",
      officeNum: "",
      cellNum: "",
      email: "domestic-sales@trytontoolservices.com",
    },
    {
      name: "International Sales",
      title: "",
      officeNum: "",
      cellNum: "",
      email: "international-sales@trytontoolservices.com",
    },
  ];

  return (
    <div className="contactBlock">
      {contacts.map((contact, index) => (
        <div className="contactItem" key={index}>
          <h5 className="contactName bodyText">{contact.name}</h5>

          {contact.title && (
            <>
              <p className="contactTitle bodyText">
                {contact.title.split("\n").map((line, i) => (
                  <React.Fragment key={i}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </p>
              <address className="contactTitle bodyText">
                {contact.address}
              </address>
            </>
          )}

          {contact.officeNum && (
            <div className="iconInfoContainer">
              <MdPhone style={{ color: "#008061" }} />
              <a
                href={`tel:${contact.officeNum.replace(/\s*\(24 hr\.\)/, "")}`}
              >
                <p className="contactNumber bodyText clickableLink">
                  {contact.officeNum}
                </p>
              </a>
            </div>
          )}
          {contact.cellNum && (
            <div className="iconInfoContainer">
              <IoIosPhonePortrait style={{ color: "#008061" }} />
              <a href={`tel:${contact.cellNum}`}>
                <p className="contactNumber bodyText clickableLink">
                  {contact.cellNum}
                </p>
              </a>
            </div>
          )}
          <a href={`mailto:${contact.email}`}>
            <p
              className="contactTitle bodyText clickableLink"
              style={{
                whiteSpace: "nowrap",
                textTransform: "lowercase",
                fontSize: contact.email.length > 34 ? "10pt" : null,
              }}
            >
              {contact.email.toLowerCase()}
            </p>
          </a>
        </div>
      ))}
    </div>
  );
}
