import React, { useEffect } from "react";
import img from "../../assets/Tryton-tile_R1.png";

export function ContactHero(props) {
  return (
    <section class="contactHero">
      <div>
        <p class="smallHeadline white">
          Contact Us
          <h1 class="largeHeadline white">
            LOCATED ACROSS
            <br /> WESTERN CANADA
            <br /> AND IN THE U.S.
          </h1>
        </p>
      </div>
    </section>
  );
}
