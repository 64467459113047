import React from "react";

export function HomeInfo(props) {
  return (
    <section className="homeInfo">
      <div>
        <h3 className="smallHeadline">Tryton Tool Services</h3>
        <br />
        <p className="bodyText">
          Tryton Tool Services is a leader and innovator in both vertical and
          horizontal downhole completion, production, workover tools and
          technology. <br />
          <br />
          Tryton has been successful in helping our clients to complete wells
          both vertical and horizontal using well established conventional
          products as well as our proven Tryton Multi-Stage Frac Systems
          ("MSFS®") – both open hole and cemented-in systems. <br />
          <br />
          Tryton has been in operation since 1997, initially with one service
          centre and has grown to ten service centres in Western Canada as well
          as a Warehouse/Distribution centre based out of Edmonton and
          Sales/Marketing in Calgary.
          <br />
        </p>
      </div>
    </section>
  );
}
