import React from "react";

export function AboutUsHero({
  subHead = "About Us",
  headline = "Quality in Everything We Do",
}) {
  return (
    <section className="contactHero">
      <div>
        <p class="smallHeadline white">{subHead}</p>
        <h1 class="largeHeadline white">{headline}</h1>
      </div>
    </section>
  );
}
