import React from "react";
import { Helmet } from "react-helmet";

export function PageNotFound(props) {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Page Not Found | Tryton Toll Services</title>
      </Helmet>
      <section className="pageNotFound contactHero">
        <div>
          <h1 class="largeHeadline white">Page Not Found</h1>
        </div>
      </section>
    </main>
  );
}
