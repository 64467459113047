import React from "react";
import { MdPhone } from "react-icons/md";
import { IoIosPhonePortrait } from "react-icons/io";

export function CorpHse(props) {
  const contacts = [
    {
      name: "Sheldon Heck",
      title: "Vice President",
      cellNum: "780.871.8880",
      email: "",
    },
    {
      name: "Randy Berryman",
      title: "Operations Manager",
      cellNum: "780.690.1451",
      email: "",
    },
    {
      name: "Lonnie Voss",
      title: "Sales Manager",
      cellNum: "403.510.7156",
      email: "",
    },
    {
      name: "Bill Williams",
      title: "HSE Manager",
      cellNum: "780.238.6897",
      email: "",
    },
    {
      name: "Keith Thomas",
      title: "HSE Coordinator, Downhole Tools",
      cellNum: "780.266.6738",
      email: "",
    },
    {
      name: "Lloydminster",
      title: "Corporate and HSE",
      officeNum: "780.875.0800 (24 hr.)",
      cellNum: "",
      email: "",
      address: "6702 – 56 St. Lloydminster, AB T9V 3A7",
    },
  ];

  return (
    <div className="contactBlock">
      {contacts.map((contact, index) => (
        <div className="contactItem" key={index}>
          <h5 className="contactName bodyText">{contact.name}</h5>
          <p className="contactTitle bodyText">{contact.title}</p>
          <address className="contactTitle bodyText">{contact.address}</address>

          {contact.officeNum && (
            <div className="iconInfoContainer">
              <MdPhone style={{ color: "#008061" }} />
              <a
                href={`tel:${contact.officeNum.replace(/\s*\(24 hr\.\)/, "")}`}
              >
                <p className="contactNumber bodyText clickableLink">
                  {contact.officeNum}
                </p>
              </a>
            </div>
          )}
          {contact.cellNum && (
            <div className="iconInfoContainer">
              <IoIosPhonePortrait style={{ color: "#008061" }} />
              <a href={`tel:${contact.cellNum}`}>
                <p className="contactNumber bodyText clickableLink">
                  {contact.cellNum}
                </p>
              </a>
            </div>
          )}
          <a href={`mailto:${contact.email}`}>
            <p className="contactTitle bodyText clickableLink">
              {contact.email}
            </p>
          </a>
        </div>
      ))}
    </div>
  );
}
