import React from "react";
import { TiSocialFacebook } from "react-icons/ti";
import { TiSocialLinkedin } from "react-icons/ti";
import { Link } from "react-router-dom";

export function Footer(props) {
  return (
    <footer>
      <div className="socialMediaLinksContainer">
        <a href="https://www.facebook.com/trytontoolservices/" target="_blank">
          <div className="socialMediaBubble">
            <TiSocialFacebook style={{ fontSize: 24, color: "white" }} />
          </div>
        </a>
        <a
          href="https://www.linkedin.com/company/tryton-tool-services-ltd./"
          target="_blank"
        >
          <div className="socialMediaBubble">
            <TiSocialLinkedin style={{ fontSize: 24, color: "white" }} />
          </div>
        </a>
      </div>
      <div className="footerLinks">
        <Link to="/terms-and-conditions/">
          <p>Terms and Conditions</p>
        </Link>
        <span> |</span>

        <Link to="/contact-us">
          <p>Contact Us</p>
        </Link>
      </div>
      <p className="disclaimer">
        © 2024 Tryton Tool Services Ltd. All Rights Reserved.
      </p>
    </footer>
  );
}
