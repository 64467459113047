import React, { useEffect } from "react";
import { AboutUsHero } from "../components/AboutUsHero/AboutUsHero";
import { AboutUsContent } from "../components/AboutUsContent/AboutUsContent";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export function AboutUsPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us | Tryton Toll Services</title>
      </Helmet>
      <AboutUsHero />
      <AboutUsContent />
    </main>
  );
}
