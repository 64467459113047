import React from "react";
import { AboutUsHero } from "../components/AboutUsHero/AboutUsHero";
import { TfiDownload } from "react-icons/tfi";
import TermsConditionsPDF from "../components/Misc/terms-and-conditions.pdf";
import { Helmet } from "react-helmet";

export function TermsConditions(props) {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms & Conditions | Tryton Toll Services</title>
      </Helmet>
      <section>
        <AboutUsHero headline="Terms and Conditions" subHead={null} />
      </section>
      <section className="homeInfo">
        <div>
          <p className="bodyText">
            A copy of the terms and conditions is available upon request.
          </p>

          {/* <a href={TermsConditionsPDF} target="_blank">
            <button className="textButton">
              Tryton Standard Terms and Conditions <TfiDownload />
            </button>
          </a> */}
        </div>
      </section>
    </main>
  );
}
