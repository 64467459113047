import React from "react";
import { MdPhone } from "react-icons/md";
import { IoIosPhonePortrait } from "react-icons/io";

export function Sales(props) {
  const contacts = [
    {
      name: "Lonnie Voss",
      cellNum: "403.510.7156",
      email: "",
    },
    {
      name: "Greg McDonnell",
      cellNum: "403.831.2740",
      email: "",
    },
    {
      name: "Matt Painchaud",
      cellNum: "587.971.9831",
      email: "",
    },
    {
      name: "Wes Lyster",
      cellNum: "403.815.4144",
      email: "",
    },

    {
      name: "Jack Proskow",
      cellNum: "403.818.3031",
      email: "",
    },

    {
      name: "Marty Pituch",
      cellNum: "587.216.1904",
      email: "",
    },

    {
      name: "John Ruzicki",
      cellNum: "403.816.7122",
      email: "",
    },

    {
      name: "Domestic Sales",
      title: "",
      officeNum: "",
      cellNum: "",
      email: "domestic-sales@trytontoolservices.com",
    },
    {
      name: "International Sales",
      title: "",
      officeNum: "",
      cellNum: "",
      email: "international-sales@trytontoolservices.com",
    },
  ];

  return (
    <div className="contactBlock">
      {contacts.map((contact, index) => (
        <div className="contactItem" key={index}>
          <h5 className="contactName bodyText">{contact.name}</h5>
          <p className="contactTitle bodyText">{contact.title}</p>
          <address className="contactTitle bodyText">{contact.address}</address>

          {contact.officeNum && (
            <div className="iconInfoContainer">
              <MdPhone style={{ color: "#008061" }} />
              <a
                href={`tel:${contact.officeNum.replace(/\s*\(24 hr\.\)/, "")}`}
              >
                <p className="contactNumber bodyText clickableLink">
                  {contact.officeNum}
                </p>
              </a>
            </div>
          )}
          {contact.cellNum && (
            <div className="iconInfoContainer">
              <IoIosPhonePortrait style={{ color: "#008061" }} />
              <a href={`tel:${contact.cellNum}`}>
                <p className="contactNumber bodyText clickableLink">
                  {contact.cellNum}
                </p>
              </a>
            </div>
          )}
          <a href={`mailto:${contact.email}`}>
            <p
              className="contactTitle bodyText clickableLink"
              style={{ whiteSpace: "nowrap", textTransform: "lowercase" }}
            >
              {contact.email.toLowerCase()}
            </p>
          </a>
        </div>
      ))}
    </div>
  );
}
