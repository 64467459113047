import React from "react";
import img from "../../assets/Homepage-Banner_R1.jpg";
import { Link } from "react-router-dom";

export function HomeHero(props) {
  return (
    <section className="homeHero">
      <div>
        <h1 className="largeHeadline white">
          Tryton Tool <br />
          Services
        </h1>
        <p className="smallDescription white">People. Products. Performance.</p>
        {/* <Link to="/products">
          <button className="greenBtn">View Our Products</button>
        </Link> */}
      </div>
    </section>
  );
}
