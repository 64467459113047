import React, { useEffect } from "react";
import { HomeHero } from "../components/HomeHero/HomeHero";
import { QuestionsBanner } from "../components/QuestionsBanner/QuestionsBanner";
import { HomeInfo } from "../components/HomeInfo/HomeInfo";
import { Helmet } from "react-helmet";

export function HomePage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Tryton Tool Services | Downhole Tools & Technology Solutions
        </title>
        <meta
          name="description"
          content="Tryton Tool Services: Leader in downhole completion, production, workover tools and technology. We provide solutions for vertical and horizontal wells, including Tryton Multi-Stage Frac Systems (MSFS®)."
        />
        <meta
          name="keywords"
          content="Tryton Tool, downhole tools, completion tools, workover tools, Multi-Stage Frac Systems, MSFS, vertical wells, horizontal wells, oilfield technology, production tools"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Tryton Tool Services" />
        <meta
          property="og:title"
          content="Tryton Tool Services | Downhole Completion & Production Tools"
        />
        <meta
          property="og:description"
          content="Since 1997, Tryton Tool Services has been providing industry-leading downhole completion and production tools, offering solutions like the Tryton Multi-Stage Frac System (MSFS®)."
        />
        <meta property="og:url" content="https://www.trytontoolservices.com" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.trytontool.com/images/tryton-tool-services.jpg"
        />
        <meta property="og:locale" content="en_CA" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Tryton Tool Services | Downhole Tools & Technology Solutions"
        />
        <meta
          name="twitter:description"
          content="Tryton Tool Services is a trusted provider of downhole completion, production, and workover tools, including the industry-leading Tryton Multi-Stage Frac Systems (MSFS®)."
        />
        <meta
          name="twitter:image"
          content="https://www.trytontool.com/images/tryton-tool-services.jpg"
        />
        <link rel="canonical" href="https://www.trytontoolservices.com" />
      </Helmet>
      <HomeHero />
      <HomeInfo />
      <QuestionsBanner />
    </main>
  );
}
