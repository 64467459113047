import React from "react";
import { MdPhone } from "react-icons/md";
import { IoIosPhonePortrait } from "react-icons/io";
import { FaFax } from "react-icons/fa";

export function FieldOfficesUS(props) {
  const contacts = [
    {
      name: "Midland Texas",
      title: "Tryton Tools USA, Inc. (Field Office)",
      officeNum: "432.561.8823",
      cellNum: "",
      faxNum: "432.561.5967",
      email: "",
      address: "7509 W. Industrial Ave. Building F Midland, TX 79706",
    },
    {
      name: "Seguin Texas",
      title: "Tryton Tools USA, Inc. (Field Office)",
      officeNum: "830.372.2755",
      cellNum: "",
      faxNum: "830.372.2799",
      email: "",
      address: "1006 Schriewer Street Seguin, TX 78155",
    },
    {
      name: "Norman Oklahoma",
      officeNum: "405.329.0682",
      cellNum: "",
      faxNum: "405.329.0685",
      email: "",
      address: "3221 Double Dr. Norman, OK 73069",
    },
    {
      name: "Liberal Kansas",
      officeNum: "620.624.6443",
      cellNum: "",
      faxNum: "620.624.6446",
      email: "",
      address: "1000 West Pancake Blvd. Liberal, KS 67901",
    },
  ];

  return (
    <div className="contactBlock">
      {contacts.map((contact, index) => (
        <div className="contactItem" key={index}>
          <h5 className="contactName bodyText">{contact.name}</h5>
          <p className="contactTitle bodyText">{contact.title}</p>
          <address className="contactTitle bodyText">{contact.address}</address>

          {contact.officeNum && (
            <div className="iconInfoContainer">
              <MdPhone style={{ color: "#008061" }} />
              <a
                href={`tel:${contact.officeNum.replace(/\s*\(24 hr\.\)/, "")}`}
              >
                <p className="contactNumber bodyText clickableLink">
                  {contact.officeNum}
                </p>
              </a>
            </div>
          )}
          {contact.cellNum && (
            <div className="iconInfoContainer">
              <IoIosPhonePortrait style={{ color: "#008061" }} />
              <a href={`tel:${contact.cellNum}`}>
                <p className="contactNumber bodyText clickableLink">
                  {contact.cellNum}
                </p>
              </a>
            </div>
          )}
          {contact.faxNum && (
            <div className="iconInfoContainer">
              <FaFax style={{ color: "#008061" }} />
              <p className="contactNumber bodyText ">{contact.faxNum}</p>
            </div>
          )}
          <a href={`mailto:${contact.email}`}>
            <p className="contactTitle bodyText clickableLink">
              {contact.email}
            </p>
          </a>
        </div>
      ))}
    </div>
  );
}
