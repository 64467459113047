import React from "react";
import { Collapse } from "antd";
import { CorpHse } from "./ContactBlocks/CorpHse";
import { Sales } from "./ContactBlocks/Sales";
import { SalesUS } from "./ContactBlocks/SalesUS";
import { FieldOffices } from "./ContactBlocks/FieldOffices";
import { FieldOfficesUS } from "./ContactBlocks/FieldOfficesUS";

export function ContactAccordion(props) {
  return (
    <section className="contactAccordionContainer">
      <div style={{ padding: "20px 0 40px 0" }}>
        <p className="bodyText" style={{ textAlign: "right" }}>
          Tryton Tool Services is a leader and innovator in both conventional
          and horizontal downhole completion, workover, production tools and
          technology.
        </p>
      </div>
      <h3 className="smallHeadline margin30">Corporate and HSE</h3>
      <Collapse
        className="accordion"
        accordion
        items={[
          {
            key: "1",
            label: "Corporate and HSE",
            children: <CorpHse />,
          },
        ]}
      />
      <h3 className="smallHeadline margin30">Canada</h3>
      <Collapse
        className="accordion"
        accordion
        items={[
          {
            key: "1",
            label: "Sales",
            children: <Sales />,
          },
          {
            key: "2",
            label: "Field Offices",
            children: <FieldOffices />,
          },
        ]}
      />
      <h3 className="smallHeadline margin30">U.S.</h3>
      <Collapse
        className="accordion"
        accordion
        items={[
          {
            key: "1",
            label: "Sales",
            children: <SalesUS />,
          },
          {
            key: "2",
            label: "Field Offices",
            children: <FieldOfficesUS />,
          },
        ]}
      />
    </section>
  );
}
