import React from "react";
import { Link } from "react-router-dom";

export function QuestionsBanner(props) {
  return (
    <section className="questionsBanner">
      <div>
        <h3 className="smallHeadline white">Questions?</h3>
        <p className="smallDescription white">How can we help?</p>
        <Link to="/contact-us" style={{ textDecoration: "none" }}>
          <button className="greenBtn">Get in touch</button>
        </Link>
      </div>
    </section>
  );
}
