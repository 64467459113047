import React from "react";

export function AboutUsContent(props) {
  return (
    <section className="aboutUsContainer">
      <h3 className="smallHeadline">About Us</h3>
      <h4 className="smallSubhead">
        We deliver reliable, innovative completion and workover technology
        through quality equipment and a dedication to exceptional service.
      </h4>
      <p style={{ marginBottom: "100px" }} className="bodyText">
        Tryton Tool Services is a proven and reliable Canadian company
        specializing in downhole tools for completions, production and
        workovers. Tryton’s continued success has been built around building
        relationships with our clients including using experienced personnel,
        quality equipment and a dedication to exceptional service.
        <br /> <br />
        Tryton puts a lot of emphasis on hiring the ‘right’ person as we have
        and continue to build our company through quality products and
        exceptional personnel. We achieve the latter through stringent hiring
        practices and a demand for technical and operationally oriented people.
        We also incorporate a training period for all new hires, which includes
        an assessment of their current abilities and development towards our
        high standard for field service specialists and mandatory participation
        in our own Downhole Completion, Production, and Workover Seminar.
        <br /> <br />
        In addition to providing equipment and services throughout Western
        Canada, Tryton is currently or has had International operations
        including Central and South America, the Middle East, Europe, Africa and
        Australia.
      </p>

      <h3 className="smallHeadline" style={{ marginBottom: "30px" }}>
        Health and Safety
      </h3>
      <p className="bodyText">
        Tryton Tool Services values health and safety and has been recognized
        for the high standards we maintain. Tryton participates in the Partners
        in Safety program and a copy of our certificate is available upon
        request.
      </p>
    </section>
  );
}
